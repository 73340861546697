@font-face {
  font-family: 'GothamVTbook';
  src: url('GothamVTbook.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamVTbold';
  src: url('GothamVTbold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GothamVTmedium';
  src: url('GothamVTmedium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamVTlight';
  src: url('GothamVTlight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'vtDelinor';
  src: url('vtDelinor.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'vtGill';
  src: url('vtGill.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'vtGillBold';
  src: url('vtGillBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'vtGillLight';
  src: url('vtGillLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'vtGlobusHandBold';
  src: url('vtGlobusHandBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'vtGlobusHandKursiv';
  src: url('vtGlobusHandKursiv.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'vtNewBasker';
  src: url('vtNewBasker.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'vtNewBaskerBold';
  src: url('vtNewBaskerBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'vtNewBaskerKurs';
  src: url('vtNewBaskerKurs.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'vtChaletBold';
  src: url('vtChaletBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'vtChaletLight';
  src: url('vtChaletLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'vtChaletRegular';
  src: url('vtChaletRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'vthelvetica45';
  src: url('vthelvetica45.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'vthelvetica65';
  src: url('vthelvetica65.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'vtmachine';
  src: url('vtmachine.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ManorScript';
  src: url('ManorScript.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ManorScriptNumbers Bold';
  src: url('ManorScriptNumbersBd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'dsMilo92Bold';
  src: url('dsMilo92Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'dsMilo92Medium';
  src: url('dsMilo92Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dsMilo92Regular';
  src: url('dsMilo92Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dsMilo92BoldNp';
  src: url('dsMilo92BoldNp.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dsMiloBold';
  src: url('dsMiloBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'dsMiloText';
  src: url('dsMiloText.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dsMiloMedium';
  src: url('dsMiloMedium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dsMiloRegular';
  src: url('dsMiloRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dsMiloBlack';
  src: url('dsMiloBlack.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'dsMiloBoldIta';
  src: url('dsMiloBoldIta.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'dsMiloSlabBold';
  src: url('dsMiloSlabBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'dsMiloSlabBoldItalic';
  src: url('dsMiloSlabBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'dsMiloSlabExtrabold';
  src: url('dsMiloSlabExtrabold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'dsMiloSlabMedium';
  src: url('dsMiloSlabMedium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('AvenirLTStd-Roman.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
